import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["backgroundImage", "backgroundOverlay"];

  connect() {
    this.backgroundOverlayTarget.classList.add("show");
    this.backgroundImageTarget.classList.add("image");
    this.backgroundImageTarget.classList.add("fadein");
  }
}
