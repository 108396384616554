import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "goal",
    "prescription",
    "dosageSpiralUp",
    "dosageLearn",
    "dosageConnection",
    "stepOneSectionOne",
    "stepOneSectionTwo",
    "stepOnErrorMessage",
    "stepOneSectionThree",
  ];
  connect() {
    console.log();
  }

  handleRadioChange() {}

  handleChange() {
    // console.log(this.prescriptionTarget.value);
    const prop = this.data.get("prop");
    let params = {};
    if (prop === "goal") {
      params = { joy_goal: this.goalTarget.value };
    } else {
      params = { ebt_prescription: this.prescriptionTarget.value };
    }

    const csrfToken = document.querySelector("[name='csrf-token']").content;
    fetch(`/users/${this.data.get("id")}`, {
      method: "PUT", // *GET, POST, PUT, DELETE, etc.
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      body: JSON.stringify({
        user: params,
      }), // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log("Data: ", data);
        // update prescription dosages
        this.dosageSpiralUpTarget.textContent = data.dosages.spiral_up;
        this.dosageLearnTarget.textContent = data.dosages.learn;
        this.dosageConnectionTarget.textContent = data.dosages.connection;
      });
  }

  onSpiralUpGoalPostSuccess(event) {
    let [data, status, xhr] = event.detail;
    const spiralUpGoals = data.spiral_up_goals;
    if (spiralUpGoals.length === 0) {
      // console.log("Spiral up goals array is empty");
      return this.stepOnErrorMessageTarget.classList.remove("hidden");
    }
    this.stepOnErrorMessageTarget.classList.add("hidden");
    this.stepOneSectionOneTarget.classList.add("hidden");
    this.stepOneSectionTwoTarget.classList.remove("hidden");
    // console.log(data);
  }

  onSpiralUpGoalPostError(event) {
    let [data, status, xhr] = event.detail;
    console.log(data.message);
  }

  onSpiralUpEBTPrescriptionPostSuccess(event) {
    let [data, status, xhr] = event.detail;
    console.log(data.ebt_prescription);
  }

  onSpiralUpEBTPrescriptionPostError(event) {
    let [data, status, xhr] = event.detail;
    console.log(data.message);
  }

  onStepOneSectionTwoClicked(e) {
    e.preventDefault();
    this.stepOneSectionOneTarget.classList.add("hidden");
    this.stepOneSectionTwoTarget.classList.add("hidden");
    this.stepOneSectionThreeTarget.classList.remove("hidden");
  }
}
