import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["dropdown", "content"];

  connect() {}

  toggle(e) {
    e.preventDefault();
    if (this.contentTarget.classList.contains("u-hidden")) {
      this.show();
    } else {
      this.hide(null);
    }
  }

  show() {
    this.dropdownTarget.classList.add("is-open");
    this.contentTarget.classList.remove("u-hidden");
  }

  hide(event) {
    //let link clicks in dropdown container go through
    if (event && event.target.classList.contains("js-dropdown-anchor")) {
      return;
    }

    //do not close dropdown if user clicks in dropdown container
    // - link clicks are handled above
    if (event && this.dropdownTarget.contains(event.target)) {
      event.preventDefault();
      return;
    }

    //otherwise close dropdown if user clicks anywhere in window outside dropdown container
    //this.buttonTextTarget.textContent = this.closedTitle
    this.dropdownTarget.classList.remove("is-open");
    this.contentTarget.classList.add("u-hidden");
  }

  disconnect() {
    this.hide(null);
  }
}
