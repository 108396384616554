import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["backgroundImage"];

  connect() {
    this.backgroundImageTarget.classList.add("image");
    this.backgroundImageTarget.classList.add("fadein");
  }
}
