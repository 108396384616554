// https://baskin.im/blog/creating-animated-modal-with-stimulus-and-tailwind

import { Controller } from "stimulus";
import { enter, leave } from "el-transition";

export default class extends Controller {
  static targets = ["backdrop", "container", "body", "closeIcon"];

  // connect() {
  // }

  open(e) {
    e.preventDefault();
    // find index of modal to unhide
    // Lock the scroll and save current scroll position
    this.lockScroll();
    enter(this.backdropTarget);
    this.containerTarget.classList.remove("hidden");
    this.closeIconTarget.classList.remove("hidden");
    enter(this.bodyTarget);
  }

  close(e) {
    // let link clicks in dropdown container go through
    if (e && e.target.classList.contains("js-modal-anchor")) {
      return;
    }

    if (e) {
      e.preventDefault();
    }

    // Unlock the scroll and restore previous scroll position
    this.unlockScroll();

    leave(this.backdropTarget);
    this.containerTarget.classList.add("hidden");
    this.closeIconTarget.classList.add("hidden");
    leave(this.bodyTarget);
  }

  disconnect() {
    this.close();
  }

  lockScroll() {
    // Add right padding to the body so the page doesn't shift
    // when we disable scrolling
    const scrollbarWidth =
      window.innerWidth - document.documentElement.clientWidth;
    document.body.style.paddingRight = `${scrollbarWidth}px`;

    // Add classes to body to fix its position
    document.body.classList.add("fixed", "inset-x-0", "overflow-hidden");

    if (this.restoreScrollValue) {
      // Save the scroll position
      this.saveScrollPosition();

      // Add negative top position in order for body to stay in place
      document.body.style.top = `-${this.scrollPosition}px`;
    }
  }

  unlockScroll() {
    // Remove tweaks for scrollbar
    document.body.style.paddingRight = null;

    // Remove classes from body to unfix position
    document.body.classList.remove("fixed", "inset-x-0", "overflow-hidden");

    // Restore the scroll position of the body before it got locked
    if (this.restoreScrollValue) {
      this.restoreScrollPosition();

      // Remove the negative top inline style from body
      document.body.style.top = null;
    }
  }

  saveScrollPosition() {
    this.scrollPosition = window.pageYOffset || document.body.scrollTop;
  }

  restoreScrollPosition() {
    if (this.scrollPosition === undefined) return;

    document.documentElement.scrollTop = this.scrollPosition;
  }
}
