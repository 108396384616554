// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["siteHeader", "siteFooter"];

  connect() {
    console.log("hello passwords!");
    const userAgent = navigator.userAgent;
    if (
      userAgent.match(/iPad/i) ||
      userAgent.match(/iPhone/i) ||
      userAgent.match(/Mac/i)
    ) {
      this.siteHeaderTarget.classList.add("u-hidden");
      this.siteFooterTarget.classList.add("u-hidden");
    }
  }
}
