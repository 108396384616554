import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["nav"];

  connect() {
    // console.log("in landing controller!");
  }

  toggleMobileMenu(e) {
    e.preventDefault();
    this.navTarget.classList.toggle("is-open");
  }
}
