import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["openIcon", "closeIcon", "mobileMenu"];

  // connect() {
  //   console.log("Hello Stimulus nav controller!");
  // }

  openMobileMenu() {
    this.openIconTarget.classList.add("hidden");
    this.closeIconTarget.classList.remove("hidden");
    this.mobileMenuTarget.classList.remove("hidden");
  }

  closeMobileMenu() {
    this.closeIconTarget.classList.add("hidden");
    this.openIconTarget.classList.remove("hidden");
    this.mobileMenuTarget.classList.add("hidden");
  }

  globalCloseMobileMenu(event) {
    if (
      this.mobileMenuTarget.contains(event.target) === false &&
      !event.target.classList.contains("js-menu-icon") &&
      !this.mobileMenuTarget.classList.contains("hidden")
    ) {
      this.closeMobileMenu();
    }
  }
}
