import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.handlePlayEvent = this.handlePlayEvent.bind(this);
    document.addEventListener("play", this.handlePlayEvent, true);
  }

  disconnect() {
    document.removeEventListener("play", this.handlePlayEvent, true);
  }

  handlePlayEvent(e) {
    const audios = document.getElementsByTagName("audio");
    Array.from(audios).forEach((audio) => {
      if (audio !== e.target) {
        audio.pause();
      }
    });
  }
}
