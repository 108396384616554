import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["menu"];

  //https://gomakethings.com/how-to-add-transition-animations-to-vanilla-javascript-show-and-hide-methods/

  toggleMenu(event) {
    event.preventDefault();
    if (this.menuTarget.classList.contains("is-visible")) {
      this.hideMenu();
      return;
    }
    this.showMenu();
  }

  showMenu() {
    const height = this.getMenuHeight();
    this.menuTarget.classList.add("is-visible");
    this.menuTarget.style.height = height;

    window.setTimeout(() => {
      this.menuTarget.style.height = "";
    }, 350);
  }

  hideMenu() {
    this.menuTarget.style.height = this.menuTarget.scrollHeight + "px";

    window.setTimeout(() => {
      this.menuTarget.style.height = 0;
    }, 1);

    window.setTimeout(() => {
      this.menuTarget.classList.remove("is-visible");
    }, 350);
  }

  getMenuHeight() {
    this.menuTarget.style.display = "block";
    const height = this.menuTarget.scrollHeight + "px";
    this.menuTarget.style.display = "";
    return height;
  }

  globalCloseMenu(event) {
    if (
      this.menuTarget.contains(event.target) === false &&
      !event.target.classList.contains("js-learn-menu-trigger") &&
      this.menuTarget.classList.contains("is-visible")
    ) {
      this.hideMenu();
    }
  }
}
