import { Controller } from "stimulus";
import intlTelInput from "intl-tel-input";

export default class extends Controller {
  static targets = ["input"];

  connect() {
    const input = this.inputTarget;
    intlTelInput(input, {
      utilsScript:
        "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/18.2.1/js/utils.min.js",
      hiddenInput: "phone",
      initialCountry: "us",
      preferredCountries: ["us", "ca", "au", "gb", "de"],
      autoPlaceholder: "polite",
      separateDialCode: true,
      nationalMode: true,
    });
  }
}
