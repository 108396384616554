import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["container", "body"];
  initialize() {
    this.colors = [
      "#1abc9c",
      "#16a085",
      "#f1c40f",
      "#f39c12",
      "#2ecc71",
      "#27ae60",
      "#ea580c",
      "#d35400",
      "#3498db",
      "#2980b9",
      "#e74c3c",
      "#c0392b",
      "#9b59b6",
      "#8e44ad",
      "#bdc3c7",
      "#34495e",
      "#2c3e50",
      "#95a5a6",
      "#7f8c8d",
      "#ec87bf",
      "#d870ad",
      "#f69785",
      "#9ba37e",
      "#b49255",
      "#009688",
      "#a94136",
    ];
  }

  connect() {
    const id = this.data.get("id");
    const colorIndex = Math.floor(Number(id) % this.colors.length);
    const finalColor = this.colors[colorIndex];
    // console.log(finalColor);
    this.containerTarget.style.backgroundColor = finalColor;
  }
}
