import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["cardElement", "cardErrors", "form", "submitButton"];

  connect() {
    var public_key = document.querySelector("meta[name='stripe-key']").content;
    var stripe = Stripe(public_key);

    this.submitButtonTarget.removeAttribute("disabled", "disabled");
    this.submitButtonTarget.classList.remove(
      "cursor-not-allowed",
      "opacity-50"
    );

    var elements = stripe.elements();
    var style = {
      base: {
        iconColor: "#666EE8",
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        lineHeight: "24px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    };

    var card = elements.create("card", {
      style: style,
      disableLink: true,
      hidePostalCode: true,
    });

    card.mount(this.cardElementTarget);

    // Handle real-time validation errors from the card Element.
    let controller = this;
    card.addEventListener("change", function (event) {
      var displayError = controller.cardErrorsTarget;
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = "";
      }
    });

    // Handle form submission.
    var form = controller.formTarget;
    form.addEventListener("submit", function (event) {
      event.preventDefault();

      var submitButton = controller.submitButtonTarget;
      submitButton.setAttribute("disabled", "disabled");
      submitButton.classList.add("cursor-not-allowed", "opacity-50");
      // console.log(submitButton);

      stripe.createToken(card).then(function (result) {
        if (result.error) {
          // Inform the user if there was an error.
          var errorElement = controller.cardErrorsTarget;
          errorElement.textContent = result.error.message;
          submitButton.removeAttribute("disabled");
          submitButton.classList.remove("cursor-not-allowed", "opacity-50");
          //console.log(submitButton);
        } else {
          // Send the token to your server.
          controller.stripeTokenHandler(result.token);
        }
      });
    });
  }

  // Submit the form with the token ID.
  stripeTokenHandler(token) {
    // Insert the token ID into the form so it gets submitted to the server
    var form = this.formTarget;
    var hiddenInput = document.createElement("input");
    hiddenInput.setAttribute("type", "hidden");
    hiddenInput.setAttribute("name", "stripe_token");
    hiddenInput.setAttribute("value", token.id);
    form.appendChild(hiddenInput);

    // Submit the form
    form.submit();
  }
}
